import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import InfoBox from "../InfoBox";
import { useTranslation } from "react-i18next";
import Variation from "./Variation.jsx";

export default function Seed({ updateUserChoices, showVariation = false }) {
  const [seedValue, setSeedValue] = useState(() => Math.floor(Math.random() * 999999999).toString());
  const { t } = useTranslation();

  const handleSeedChange = (event) => {
    const value = event.target.value;
    setSeedValue(value);
    updateUserChoices("seed", value);
  };

  useEffect(() => {
    if (seedValue < 1) setSeedValue("1");
    updateUserChoices("seed", seedValue);

    const inputElement = document.getElementById("location");
    if (inputElement) {
      const inputLength = seedValue.length;
      let fontSize = Math.max(110 - 10 * (inputLength - 1), 34);
      inputElement.style.fontSize = `${fontSize}px`;
      
      if (inputLength > 10) {
        setSeedValue(seedValue.slice(0, 10));
      }
    }
  }, [seedValue]);

  const generateRandomValue = () => {
    const randomNumber = Math.round(Math.random() * 999999999).toString();
    setSeedValue(randomNumber);
    updateUserChoices("seed", randomNumber);
  };

  const handleSeedRemove = () => {
    setSeedValue("1");
    updateUserChoices("seed", "1");
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <h1 className="font-bold font-title flex items-center text-black text-3xl">
        SEED
        <div className="w-[1px] h-[1px] relative">
          <InfoBox
            customClass="absolute top-1/2 -translate-y-1/2 ml-3"
            text={t("Each Seed represents numerical value that will create an unique image at the end of the process, ex: 1, 2, 5628, 55147.")} 
          />
        </div>
      </h1>
      
      <input
        type="number"
        value={seedValue}
        onChange={handleSeedChange}
        min={1}
        max={999999999}
        id="location"
        className="font-title focus:outline-none text-center max-w-64 border-0"
        style={{ height: '80px' }}
      />

      {/* Renderização condicional SIMPLIFICADA */}
      {showVariation && (
        <div className='flex-2 mt-4'>  {/* apenas redecorate */}
          <Variation updateUserChoices={updateUserChoices} />
        </div>
      )}

      <div className="flex items-center mt-4">
        <button
          onClick={generateRandomValue}
          className="font-title text-xl border-2 border-black border-solid p-2 mt-2 ease-in-out duration-300 transition-colors hover:bg-black hover:text-white"
        >
          {t("Randomize")}
        </button>
        <FaTrash
          size={22}
          color='black'
          className='ml-3 mt-2 cursor-pointer hover:animate-pulse hover:animate-infinite'
          onClick={handleSeedRemove}
        />
      </div>
    </div>
  );
}
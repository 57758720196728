import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import InfoBox from "../InfoBox";

export default function OriginalityReDeCor({ updateUserChoices, initialValue}) {
  const [originality, setOriginality] = useState(1.0); // Valor inicial 0
  const [isEditable, setIsEditable] = useState(false); // Toggle inicialmente desativado
  const { t } = useTranslation();

  const handleOriginalityChange = (event) => {
    const value = event.target.value;
    setOriginality(value);
    updateUserChoices("OriginalityRange", value);
    updateUserChoices("OriginalityToogle", isEditable);

    //console.log("Originality value changed to:", value);
  };

  // Função para alternar o estado do toggle "Enable Editing"
  const handleToggleChange = () => {
    const newToggleState = !isEditable; // Inverte o estado atual
    setIsEditable(newToggleState); // Atualiza o estado
    updateUserChoices("OriginalityToogle", newToggleState);
    console.log("Enable Editing toggled:", newToggleState); // Exibe o novo estado no console
  };

  return (
    <div className="items-center flex flex-col">
      <div className="flex">
        <h1 className='font-title lg:justify-center font-bold py-8 lg:py-2 lg:mb-2 flex items-center'>
          {t("ORIGINALITY")}
          <div className="w-[0.01px] h-[0.01px] relative">
            <InfoBox
              customClass={"absolute left-1 top-1/2 -translate-y-1/2"}
              text={t("Lower originality keep more of the image intact, Higher originality let the AI create more on top of the original.")}
            />
          </div>
        </h1>
      </div>
      <div className="flex justify-between max-w-screen items-center w-[90vw] mb-4 lg:w-[35rem]">
        <p className="text-white font-title font-bold px-4 py-1 bg-black rounded-full">{t("LOW")}</p>
        <p className="text-white font-title font-bold px-4 py-1 bg-black rounded-full">{t("HIGH")}</p>
      </div>
      <input
        type="range"
        min="0.1"
        max="1.0"
        step="0.01"
        value={originality}
        onChange={handleOriginalityChange}
        disabled={isEditable}
        className={`lg:w-[35rem] w-[70vw] appearance-none h-1 rounded-full bg-black opacity-85" outline-none transition-all duration-300`}
      />
      <p className='font-paragraph text-xl mt-3 lg:text-justify'>
        {t("The higher this value is more the input image will change.")}
      </p>
    </div>
  );
}
import { React, useState } from "react";
import { useTranslation } from "react-i18next";

export default function OriginalResemblance({ updateUserChoices, initialValue }) {
  const [originalResemblance, setOriginalResemblance] = useState(initialValue);
  const {t} = useTranslation();

  const handleOriginalResemblanceChange = (event) => {
    const value = event.target.value;
    setOriginalResemblance(value);
    updateUserChoices("OriginalResemblanceRange", value);
  };

  return (
    <div className="items-center flex flex-col">
      <h1 className='font-title lg:justify-center 
      font-bold py-8 lg:py-2 lg:mb-2'>{t("ORIGINAL RESEMBLANCE")}</h1>
      <div className="flex justify-between max-w-screen items-center w-[90vw] mb-4 lg:w-[25.5rem]">
      <p className="text-white font-title font-bold px-3 py-1 bg-black rounded-full">
        {t("1")}
        </p>
        <p className="text-white font-title font-bold px-3 py-1 bg-black rounded-full">
        {t("2")}
        </p>
        <p className="text-white font-title font-bold px-3 py-1 bg-black rounded-full">
        {t("3")}
        </p>
        <p className="text-white font-title font-bold px-3 py-1 bg-black rounded-full">
        {t("4")}
        </p>
        <p className="text-white font-title font-bold px-3 py-1 bg-black rounded-full">
        {t("5")}
        </p>
      </div>
      <input
        type="range"
        min="1"
        max="5"
        value={originalResemblance}
        onChange={handleOriginalResemblanceChange}
        className="lg:w-[24.8rem] w-[70vw] appearance-none h-1 rounded-full bg-black opacity-85 outline-none transition-all duration-300"
      />
       <p className='font-paragraph text-xl mt-3 mb-1 lg:text-justify'>{t("The higher this value is, higher the influence on the AI render")}</p>
    </div>
  );

}

OriginalResemblance.defaultProps = {
  initialValue: "2"
}
import React, { useState, useEffect, useContext } from "react";
import ReactModal from "react-modal";
import ContainerCard from "./Components/ContainerCard/ContainerCard";
import MasonryLayout from "./Components/MasonryLayout/MasonryLayout";
import Navbar from "../Navbar";
import Footer from "../Footer";
import styles from "./App.module.css";
import {
  FaXmark,
  FaDownload,
  FaHeart,
  FaTriangleExclamation,
} from "react-icons/fa6";
import "./Gallery.css";
import "../../index.css";
import axios from "axios";
import Cookies from "js-cookie";
// import { jwtDecode } from "jwt-decode";}
import { add_Back } from "../../address";
// import PopUpComponent from "../../components/Generic/PopupComponent";
import perfil_default from "../../assets/perfil_default.jpg";
import { useTranslation } from "react-i18next";
import { Context } from "../../App";
import NewPopUpComponent from "../Generic/NewPopUpComponent";

const CommunityGallery = () => {
  const [warningMessage, setWarningMessage] = useContext(Context);

  //  // Função para verificar se o token está presente e válido
  //  function verificarToken() {
  //   const token = Cookies.get('token');
  //   if (!token) {
  //     // Token não encontrado no localStorage
  //     return false;
  //   }

  //   // Decodificar o token para verificar sua validade
  //   try {
  //     const decodedToken = jwtDecode(token); // Use jwtDecode em vez de jwt_decode
  //     const currentTime = Date.now() / 1000; // Obtém o tempo atual em segundos

  //     // Verificar se o token expirou
  //     if (decodedToken.exp < currentTime) {
  //       // Token expirado
  //       return false;
  //     }

  //     // Token válido
  //     return true;
  //   } catch (error) {
  //     //console.error('Erro ao decodificar o token:', error);
  //     return false; // Token inválido
  //   }
  // }

  // // Função para redirecionar para a página de login
  // function redirectToLogin() {
  //    window.location.href = '/Login'; // Redireciona para a página de login
  // }

  // // Verificar o token ao carregar a página
  // if (!verificarToken()) {
  //   redirectToLogin(); // Redireciona para a página de login se o token não for válido
  // }
  const { i18n } = useTranslation();
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [favoriteImages, setFavoriteImages] = useState({});
  const [isHovering, setIsHovering] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Estado para controlar o carregamento das imagens
  const [page, setPage] = useState(1);
  const [imagesPerPage] = useState(12);
  const [loadingMore, setLoadingMore] = useState(false); // Estado para controlar o carregamento adicional de imagens
  const [isHeartRed, setIsHeartRed] = useState(false);
  const [likes, setLikes] = useState(0);
  const [allImages, setAllImages] = useState([]);
  const [lastClickTime, setLastClickTime] = useState(0);
  const LIKE_INTERVAL = 500; // Intervalo em milissegundos

  useEffect(() => {
    i18n.init({ ns: "gallery" });
  }, [i18n]);

  const openModal = (categoryImage) => {
    setSelectedImage(categoryImage);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  const handleHovering = () => {
    setIsHovering(!isHovering);
  };

  const handleReportClick = () => {
    // Lógica para denúncia
    // alert("Denúncia registrada!");
    setWarningMessage("Reported succesfully!");
  };

  const [qtdFotos, setQtdFotos] = useState(1);
  const { t } = useTranslation();

  const handleHeartClick = async (event) => {
    event.stopPropagation();
    const currentTime = Date.now();

    if (currentTime - lastClickTime < LIKE_INTERVAL) {
      // Se os cliques forem rápidos, mantenha o like
      setIsHeartRed(true);
      setLastClickTime(currentTime);
      return;
    }

    // Alterna o estado do like e atualiza o banco de dados
    setIsHeartRed(!isHeartRed);
    setLastClickTime(currentTime);

    const token = Cookies.get("token");
    try {
      const newLikes = !isHeartRed ? likes + 1 : likes - 1;
      const response = await axios.put(
        `${add_Back}/galeria/${selectedImage.id}`,
        {
          qde_likes: newLikes,
          headers: {
            Authorization: `${token}`, // Inclua o token no cabeçalho
            "Content-Type": "application/json",
          },
        }
      );
      
      setLikes(response.data.qde_likes);
    } catch (error) {
      setWarningMessage("Error while updating likes number")
    }
  };

  useEffect(() => {
    document.title = "Community Gallery - Arcseed AI";
    return () => {
      document.title = "Arcseed AI";
    };
  }, []);

  const handleDownloadClick = async (imageUrl) => {
    try {
      // Realiza uma requisição para obter o blob da imagem
      const response = await fetch(imageUrl.imagem, { mode: 'cors', cache: 'no-cache' });
      if (!response.ok) {
        throw new Error(`Erro ao buscar a imagem: ${response.status} ${response.statusText}`);
      }

      // Converte a resposta para Blob
      const blob = await response.blob();

      // Cria um link temporário para download
      const link = document.createElement("a");
      const objectURL = URL.createObjectURL(blob);

      // Configura o link para download
      link.href = objectURL;
      link.download = `${imageUrl.nick_name}.png`;

      // Adiciona ao DOM, clica e remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Libera o objeto temporário da memória
      URL.revokeObjectURL(objectURL);

      console.log("Download realizado com sucesso.");
    } catch (error) {
      console.error("Erro ao baixar a imagem:", error);
      alert("Não foi possível baixar a imagem.");
    }
  };
  //console.log(token);
  const preloadImages = async () => {
    try {
      const response = await axios.get(
        `${add_Back}/galeria?page=${page}&limit=${imagesPerPage}`,
        {
          headers: {
            // 'Authorization': `${token}`, // Inclua o token no cabeçalho
            "Content-Type": "application/json",
          },
        }
      );
      const imagesWithoutQuotes = response.data.map((image) => ({
        ...image,
        imagem: image.imagem.replace(/^"|"$/g, ""),
      }));
      // Filtrar imagens para garantir que apenas imagens únicas sejam carregadas
      const uniqueImages = filterUniqueImages(imagesWithoutQuotes);
      setAllImages(uniqueImages);
      setIsLoading(false);
    } catch (error) {
      setWarningMessage("Error while getting images")
    }
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      preloadImages();
    }, 1000); // 1000 milissegundos = 1 segundo

    return () => clearTimeout(timeoutId);
  }, []);
  // Array vazio para garantir que o efeito seja executado apenas uma vez

  const loadMoreImages = async () => {
    if (loadingMore) return; // Se já estiver carregando mais imagens, não faça nada
    setLoadingMore(true); // Sinaliza que estamos carregando mais imagens
    try {
      const response = await axios.get(
        `${add_Back}/galeria?page=${page + 1}`, // Apenas incrementar a página em 1 aqui
        {
          headers: {
            //Authorization: `${token}`, // Inclua o token no cabeçalho
            "Content-Type": "application/json",
          },
        }
      );
      const imagesWithoutQuotes = response.data.map((image) => ({
        ...image,
        imagem: image.imagem.replace(/^"|"$/g, ""),
      }));
      // Filtrar imagens para garantir que apenas imagens únicas sejam carregadas
      const uniqueImages = filterUniqueImages(imagesWithoutQuotes);
      setAllImages((prevImages) => [...prevImages, ...uniqueImages]);

      if(response.data.length <= 0) { //Faz com que aparece o That's all
        setQtdFotos(0);
      }

      setPage((prevPage) => prevPage + 1); // Atualiza a página corretamente em 1
    } catch (error) {
      setWarningMessage("Error while getting images");
    } finally {
      setLoadingMore(false); // Marca que a operação de carregar mais imagens foi concluída
    }
  };

  // Função para filtrar imagens únicas com base no ID
  const filterUniqueImages = (images) => {
    const uniqueIds = new Set();
    return images.filter((image) => {
      if (!uniqueIds.has(image.id)) {
        uniqueIds.add(image.id);
        return true;
      }
      console.log(`Imagem duplicada: ${image.id}`);
      return false;
    });
  };

  return (
    <div>
      <NewPopUpComponent />
      <Navbar
        title={i18n.t("COMMUNITY GALLERY")}
        subtitle={i18n.t("Images created by the community.")}
        description="Here you can see all the images that other users have rendered using our platform and have kept public."
      />
      <div
        className="flex justify-content-center pt-[118px] min-h-screen"
        style={{ paddingLeft: "50px", paddingRight: "50px" }}
      >
        <ContainerCard>
          <div
            className={`${styles["gallery-setting"]} flex flex-col lg:flex-row justify-end align-items-center`}
            style={{ marginLeft: "-58px" }}
          >
            {" "}
          </div>
          <div className="-mt-15">
            {isLoading ? (
              <div
                className={`font-title font-bold text-2xl
              animate-pulse transition-opacity duration-500 ease-in-out`}
              >
                {i18n.t("LOADING...")}
              </div>
            ) : (
              <>
                <MasonryLayout images={allImages} onImageClick={openModal} />
                {loadingMore ? (
                  <div
                    className={`flex font-title font-bold text-2xl 
                  animate-pulse justify-center items-center
                  transition-opacity duration-500 ease-in-out -mt-2 mb-4`}
                  >
                    {i18n.t("LOADING...")}
                  </div>
                ) : (
                  (qtdFotos > 0 ? 
                  <button
                    onClick={loadMoreImages}
                    className={`
                  black-border-2 font-title text-2xl my-2 p-4 bg-white
                  text-black transition-all ease-in-out duration-500
                  hover:bg-black hover:text-white font-bold -mt-2 mb-4`}
                  >
                    {i18n.t("Load more")}
                  </button>
                  :
                  <h1
                  className="font-title text-2xl font-bold">{t("That's all")}</h1>
                  )
                )}
              </>
            )}
            <ReactModal
              className="absolute top-0 left-0 transition-opacity opacity-100 duration-500 w-[100vw] h-full flex flex-row justify-center items-center"
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              contentLabel="Modal"
              ariaHideApp={false}
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  zIndex: 1000,
                },
              }}
            >
              {selectedImage && (
                <div className="flex flex-row justify-center items-center h-[90%]">
                  <button
                    style={{
                      zIndex: 50
                    }}
                    className="absolute top-[3%] left-[85%] lg:top-[3%] lg:left-[95%] scale-125 place-items-start"
                    onClick={closeModal}
                  >
                    <FaXmark color="white" size={35} />
                  </button>
                  <img
                    src={`${selectedImage.imagem}`}
                    alt={selectedImage.nick_name}
                    className="hidden lg:inline"
                    style={{
                      width: "auto",
                      height: "90%",
                      zIndex: 1000,
                    }}
                  />
                  <div className="relative inline lg:hidden">
                    <div className="max-w-[100%] max-h-[auto]">
                    <img
                      src={`${selectedImage.imagem}`}
                      alt={selectedImage}
                      style={{
                        width: "100%",
                        height: "fit-content",
                        objectFit: "contain"
                      }}
                      className="animate-ease-in-out animate-duration-500 lg:hidden"
                    />
                    </div>
                    <div className="absolute flex bottom-[-13%] z-30 md:hidden lg:hidden w-full h-[10%]">
                      <FaDownload
                        size={32}
                        color="white"
                        href={`${selectedImage.imagem}`}
                        download={`${selectedImage.nick_name}.jpg`}
                        onMouseEnter={handleHovering}
                        onMouseLeave={handleHovering}
                        className="cursor-pointer ml-4"
                      ></FaDownload>
                      <FaHeart
                        size={32}
                        className="ml-4"
                        onClick={handleHeartClick}
                        color={isHeartRed ? "red" : "white"}
                        style={{
                          transition: "color 0.5s ease",
                          cursor: "pointer",
                        }}
                      />
                      <FaTriangleExclamation
                        size={32}
                        className="ml-4"
                        color="white"
                        onClick={handleReportClick} // Adicione sua função de denúncia aqui
                        style={{
                          cursor: "pointer",
                          transition: "color 0.5s ease",
                        }}
                      />
                    </div>
                    <div className="absolute flex items-end top-[-14%] w-full h-[10%]">
                      <img
                        src={
                          `${selectedImage.imagem_perfil}` ||
                          `${perfil_default}`
                        }
                        className="w-[16vw] h-[16vw] ml-3 max-w-52 max-h-52"
                      />
                      <h2 className="font-paragraph text-[12vw] max-w-52 max-h-52 ml-3 text-white">
                        {selectedImage.nick_name}
                      </h2>
                    </div>
                  </div>
                  <div className="flex-col hidden lg:inline-flex items-center justify-center h-[90%] bg-white py-4 px-4 ">
                    <img
                      src={
                        `${selectedImage.imagem_perfil}` || `${perfil_default}`
                      }
                      className=" mb-5 hover:w-28 hover:h-28 rounded-[100%] ease-in-out group-transition duration-500 w-20 h-20"
                    ></img>
                    <h1 className="text-sm font-title font-bold">
                      {i18n.t("REQUESTED BY")}
                    </h1>
                    <h2 className="font-paragraph text-2xl -mt-2">
                      {selectedImage.nick_name}
                    </h2>
                    <FaDownload
                      size={28}
                      onClick={() => handleDownloadClick(selectedImage)}
                      download={`${selectedImage.nick_name}.jpg`}
                      onMouseEnter={handleHovering}
                      onMouseLeave={handleHovering}
                      className="cursor-pointer w-full mt-3"
                    ></FaDownload>
                    <p
                      className={`font-title mt-3 ${
                        isHovering
                          ? `animate-fade-up animate-duration-500`
                          : `animate-fade animate-alternate-reverse animate-duration-500`
                      }`}
                    >
                      {i18n.t("DOWNLOAD NOW")}!
                    </p>
                  </div>
                </div>
              )}
            </ReactModal>
          </div>
        </ContainerCard>
      </div>
      <div className={`${isLoading ? `absolute bottom-0 w-full` : ``}`}>
        <Footer />
      </div>
    </div>
  );
};

export default CommunityGallery;

import React, { useState } from "react";

export default function ToggleSwitch({ updateUserChoices }) {
  const [toggleState, setToggleState] = useState(false);

  const toggleSwitch = () => {
    setToggleState((prev) => !prev);
    updateUserChoices("dreamLogic", !toggleState);
  };

  return (
    <div className="flex items-center space-x-4">
      <span className="text-xl font-bold">DREAM LOGIC</span>
      <button
        onClick={toggleSwitch}
        className={`w-16 h-8 flex items-center rounded-full p-1 transition-colors duration-300 ${toggleState ? "bg-black" : "bg-gray-400"}`}
      >
        <div
          className={`w-6 h-6 bg-white rounded-full shadow-md transform transition-transform duration-300 ${toggleState ? "translate-x-8" : "translate-x-0"}`}
        ></div>
      </button>
    </div>
  );
}

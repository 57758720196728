import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import InfoBox from "../InfoBox";

export default function ShapeGuidance({ updateUserChoices }) {
  const [shapeGuidance, setShapeGuidance] = useState(2);
  const {t} = useTranslation();

  const handleShapeGuidanceChange = (event) => {
    const value = event.target.value;
    setShapeGuidance(value);
    updateUserChoices("ShapeGuidanceRange", value);
  };

  return (
    <div className="items-center flex flex-col">
      <div className="flex">
      <h1 className='font-title lg:justify-center 
      font-bold py-8 lg:py-2 lg:mb-2 flex items-center'>
        {t("SHAPE GUIDANCE")}
      <div className="w-[0.01px] h-[0.01px] relative">
        <InfoBox
        customClass={"absolute left-1 top-1/2 -translate-y-1/2"}
        text={t("If your results are not following the contours of the image well increasing the strictness of the guidance may fix the problem.")}/>
      </div>
        </h1>
      </div>
      <div className="flex justify-between max-w-screen items-center w-[90vw] mb-4 lg:w-[25.5rem]">
      <p className="text-white font-title px-3 py-1 bg-black rounded-full">
        {t("1")}
        </p>
        <p className="text-white font-title px-3 py-1 bg-black rounded-full">
        {t("2")}
        </p>
        <p className="text-white font-title px-3 py-1 bg-black rounded-full">
        {t("3")}
        </p>
        <p className="text-white font-title px-3 py-1 bg-black rounded-full">
        {t("4")}
        </p>
        <p className="text-white font-title px-3 py-1 bg-black rounded-full">
        {t("5")}
        </p>
      </div>
      <input
        type="range"
        min="1"
        max="5"
        value={shapeGuidance}
        onChange={handleShapeGuidanceChange}
        className="lg:w-[24.8rem] w-[70vw] appearance-none h-1 rounded-full bg-black opacity-85 outline-none transition-all duration-300"
      />
      <p className='font-paragraph text-xl mt-3 mb-1 lg:text-justify'>
        {t("How long the AI should follow the contours of the original image")}</p>
    </div>
  );

}
import { React, useState, useEffect } from "react";
import ImageUploader from "../components/RenderComponents/ImageUploader";
import ImageCropper from "../components/RenderComponents/ImageCropper";
import AIModels from "../components/RenderComponents/AIModels";
import Seed from "../components/RenderComponents/Seed";
import Type from "../components/RenderComponents/Type";
import CustomRange from "../components/RenderComponents/CustomRange.jsx";
// import WhatInterior from "../components/RenderComponents/WhatInterior";
import whatExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/whatExteriors.json";
import whatInteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/whatInteriors.json";
import timeExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/timeExteriors.json";
import timeInteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/timeInteriors.json";
import styleInteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/styleInteriors.json";
import styleExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/styleExteriors.json";
import whereExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/whereExteriors.json";
import weatherExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/weather.json";
// import Time from "../components/RenderComponents/Time";
// import Style from "../components/RenderComponents/Style";
import GenericRangeComponent from "../components/RenderComponents/GenericRangeComponent.jsx";
import shapeExposureJSON from "../components/RenderComponents/slidersParamJSONConfig/shapeExposure.json";
import InputDepth from "../components/RenderComponents/InputDepth.jsx";
import ColorPalette from "../components/RenderComponents/ColorPalette";
import MaterialWalls from "../components/RenderComponents/Materials";
import WallpaperGen from "../assets/wallpaperRenderPage.webp";
import Visibility from "../components/RenderComponents/Visibility";
import AIImmaginationRange from "../components/RenderComponents/AIImmaginationRange.jsx";
import ShapeStrength from "../components/RenderComponents/ShapeStrength.jsx";
import RenderButton from "../components/RenderComponents/RenderButton";
import Navbar, { navbarHeight } from "../components/Navbar";
import Footer from "../components/Footer";
import Originality from "../components/RenderComponents/Originality";
// import WhatExterior from "../components/RenderComponents/WhatExterior";
// import Floors from "../components/RenderComponents/Floors";
// import Where from "../components/RenderComponents/WhereExterior";
// import Weather from "../components/RenderComponents/Weather";
import Precision from "../components/RenderComponents/Precision.jsx";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { geraPrompt as geraPromptExteriorLandscapePro } from "../apiComfy/Sketch/Sketch_Exterior_Pro_Landscape.js";
import { geraPrompt as geraPromptExteriorSquarePro } from "../apiComfy/Sketch/Sketch_Exterior_Pro_Square.js";
import { geraPrompt as geraPromptExteriorPortraitPro } from "../apiComfy/Sketch/Sketch_Exterior_Pro_Portrait.js";

import { geraPrompt as geraPromptExteriorLandscapeStandart } from "../apiComfy/Sketch/Sketch_Exterior_Standart_Landscape.js";
import { geraPrompt as geraPromptExteriorSquareStandart } from "../apiComfy/Sketch/Sketch_Exterior_Standart_Square.js";
import { geraPrompt as geraPromptExteriorPortraitStandart } from "../apiComfy/Sketch/Sketch_Exterior_Standart_Portrait.js";

import { geraPrompt as geraPromptInteriorLandscapePro } from "../apiComfy/Sketch/Sketch_Interior_Pro_Landscape.js";
import { geraPrompt as geraPromptInteriorSquarePro } from "../apiComfy/Sketch/Sketch_Interior_Pro_Square.js";
import { geraPrompt as geraPromptInteriorPortraitPro } from "../apiComfy/Sketch/Sketch_Interior_Pro_Portrait.js";

import { geraPrompt as geraPromptInteriorLandscapeStandart } from "../apiComfy/Sketch/Sketch_Interior_Standart_Landscape.js";
import { geraPrompt as geraPromptInteriorSquareStandart } from "../apiComfy/Sketch/Sketch_Interior_Standart_Square.js";
import { geraPrompt as geraPromptInteriorPortraitStandart } from "../apiComfy/Sketch/Sketch_Interior_Standart_Portrait.js";
import ShapeExposure from "../components/RenderComponents/ShapeExposure.jsx";
import Accordion from "../components/RenderComponents/Accordion.jsx";
import { useTranslation } from "react-i18next";
import GenericRenderComponent from "../components/RenderComponents/GenericRenderComponent.jsx";
import { t } from "i18next";
import NewPopUpComponent from "../components/Generic/NewPopUpComponent.jsx";
import InfoBox from "../components/InfoBox.jsx";
import { offensiveWords } from "../badwords.js";
import { add_Back } from "../address.js";
import PromptToggleOrMessage from "../components/RenderComponents/PromptToggleOrMensage.jsx";
//import FineTuningAccessMessage from "../components/RenderComponents/FineTuningAccessMessage.js";
import DesignerPersonality from "../components/RenderComponents/DesignerPersonality.jsx";
import DepthExposure from "../components/RenderComponents/DepthExposure.jsx";
import ContourStrength from "../components/RenderComponents/ContourStrength.jsx";
import ContourExposure from "../components/RenderComponents/ContourExposure.jsx";


export default function Sketch2RenderInteriorPage() {
  const isUserFree = () => {
    return localStorage.getItem("id_plan") === "1"; // Retorna true se for usuário Free
  };

  const standardColors = {
    active: "bg-[#fffe80] text-black",
    inactive: "bg-[#ffffb3] text-black",
    hover: "hover:bg-[#fffe80] hover:text-black",
    valor: 2,
  };

  const [isPromptEnabled, setIsPromptEnabled] = useState(false);
  const [promptInput, setPromptInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [previousTranslatedText, setPreviousTranslatedText] = useState("");
  const [currentPrompt, setCurrentPrompt] = useState("");  // This will store the latest input before translation
  const [isTranslating, setIsTranslating] = useState(false); // Handle translation state




  // Function to handle the input change
  const handleInputChange = (e) => {
    let inputValue = e.target.value;
    let containsOffensiveWord = false;
    let foundTerm = null;

    // Extrair os nomes das opções dos dois arquivos JSON
    const exteriorOptions = whatExteriorsJSON.param.options.map((opt) => opt.name);
    const interiorOptions = whatInteriorsJSON.param.options.map((opt) => opt.name);

    // Combinar todas as opções em um único array
    const allOptions = [...exteriorOptions, ...interiorOptions];

    // Verifica se há palavras ofensivas e remove-as do input
    offensiveWords.forEach((word) => {
      const regex = new RegExp(`\\b${word}\\b`, "gi");
      if (regex.test(inputValue)) {
        containsOffensiveWord = true;
        inputValue = inputValue.replace(regex, "");  // Remove a palavra ofensiva
      }
    });

    // Verificar se o input contém alguma das opções dos JSONs
    allOptions.forEach((option) => {
      const regex = new RegExp(`\\b${option}\\b`, "gi");
      if (regex.test(inputValue)) {
        foundTerm = option;  // Salva o termo encontrado
      }
    });


    // Mostra mensagem de erro se houver palavras ofensivas
    if (containsOffensiveWord) {
      setErrorMessage("Your input contains offensive words and has been modified.");
    } else {
      setErrorMessage("");
    }

    // Atualiza os estados relacionados ao input
    setCurrentPrompt(inputValue);
    setPromptInput(inputValue);
    setUserChoices((prevChoices) => ({
      ...prevChoices,
      prompt: inputValue,
      what: foundTerm
    }));
  };
  // Function to handle translation when the button is clicked
  const handleTranslateClick = async () => {
    // Disable the button and change the text to "Translating..."
    setIsTranslating(true);

    try {
      const response = await fetch(`${add_Back}/translate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text: promptInput }),  // Send the current prompt text
      });

      const data = await response.json();

      if (response.ok) {
        // Update the prompt input with the translated text
        setPromptInput(data.translatedText);
        setUserChoices((prevChoices) => ({
          ...prevChoices,
          prompt: data.translatedText,
        }));
      } else {
        console.error('Translation failed:', data.message);
      }
    } catch (error) {
      console.error('Error during translation:', error);
    } finally {
      // Re-enable the button and reset the text after 2 seconds
      setTimeout(() => {
        setIsTranslating(false);
      }, 2000); // 2-second delay
    }
  };



  const handlePromptToggle = () => {
    const newIsPromptEnabled = !isPromptEnabled;

    setIsPromptEnabled(newIsPromptEnabled);

    if (newIsPromptEnabled) {
      // When prompt is enabled, apply the Exterior finetune settings
      setUserChoices({
        ...userChoices,
        aiModels: null,
        type: "PROMPT",  // Set type to PROMPT
        what: null,
        time: null,
        style: "Modern",
        colorPalette: null,
        materials: null,
        whereExterior: null,
        weather: null,
        //InputDepthRange: "2",  // Set InputDepthRange like Exteriors, since it's needed for prompt
        ShapeStrengthRange: "2",  // Set default values for finetuning
        ShapeExposureRange: "2",
        AIImmaginationRange: "3",
        InputDepthRange: "2",
        contourStrength: "3",
        contourExposure: "4",
        DepthExposureRange: "3"
      });
    } else {
      // If toggling prompt off, restore choices based on Interiors or Exteriors
      setUserChoices({
        ...userChoices,
        type: null,  // Keep the type if set
        aiModels: "default",
        what: null,
        time: null,
        style: "Modern",
        colorPalette: null,
        materials: null,
        whereExterior: null,
        weather: null,
        // Restore `InputDepthRange` for exteriors only, null for interiors
        //InputDepthRange: userChoices.type === "EXTERIORS" ?
        //(userChoices.precision === "STANDARD" ? "1" : "2") : null,
        ShapeStrengthRange: "2",  // Set default values for finetuning
        ShapeExposureRange: "2",
        AIImmaginationRange: "3",
        InputDepthRange: "3",
        contourStrength: "3",
        contourExposure: "4",
        DepthExposureRange: "3"
      });
    }
  };


  const professionalColors = {
    active: "bg-[#fcac7f] text-black",
    inactive: "bg-[#fdd7bc] text-black",
    hover: "hover:bg-[#fcac7f] hover:text-black",
    valor: 3,
  };

  const [moedas, setMoedas] = useState(() => {
    const storedMoedas = localStorage.getItem("moedas_total");
    return storedMoedas ? parseInt(storedMoedas, 10) : 0;
  });

  const handleMoedasUpdate = (newMoedas) => {
    setMoedas(newMoedas);
    localStorage.setItem("moedas_total", newMoedas);
  };

  useEffect(() => {
    i18n.init({
      ns: ["renderComponents", "Footers"]
    });
  }, [i18n]);

  // Função para verificar se o token está presente e válido
  const token = Cookies.get("token");
  function verificarToken() {

    if (!token) {
      // Token não encontrado no localStorage
      return false;
    }

    // Decodificar o token para verificar sua validade
    try {
      const decodedToken = jwtDecode(token); // Use jwtDecode em vez de jwt_decode
      const currentTime = Date.now() / 1000; // Obtém o tempo atual em segundos

      // Verificar se o token expirou
      if (decodedToken.exp < currentTime) {
        // Token expirado
        return false;
      }

      // Token válido
      return true;
    } catch (error) {
      //console.error('Erro ao decodificar o token:', error);
      return false; // Token inválido
    }
  }

  // Função para redirecionar para a página de login
  function redirectToLogin() {
    window.location.href = "/Login"; // Redireciona para a página de login
  }

  // Verificar o token ao carregar a página
  if (!verificarToken()) {
    redirectToLogin(); // Redireciona para a página de login se o token não for válido
  }

  const [userChoices, setUserChoices] = useState({
    imageUploader: null,
    imageCropper: null,
    aiModels: null,
    seed: null,
    type: null,
    what: null,
    whereExterior: null,
    time: null,
    weather: null,
    style: "Modern",
    colorPalette: null,
    materials: null,
    effects: null,
    visibility: "PUBLIC",
    precision: "STANDARD",
    OriginalityRange: "1.0",
    InputDepthRange: "3",
    contourStrength: "3",
    contourExposure: "4",
    DepthExposureRange: "4",
    isAdvancedFineTuningEnabled: false,
    FineTuninAll: "3"
  });

  useEffect(() => {
    if (userChoices.type === "EXTERIORS") {
      setUserChoices((prevChoices) => ({
        ...prevChoices,
        contourExposure: "3", // Definir como 3 para EXTERIORS
      }));
    } else {
      setUserChoices((prevChoices) => ({
        ...prevChoices,
        contourExposure: "4", // Definir como 4 para outros tipos
      }));
    }
  }, [userChoices.type]);

  useEffect(() => {
    if (userChoices.type === "EXTERIORS") {
      setUserChoices((prevChoices) => ({
        ...prevChoices,
        InputDepthRange: "2", // Define como 2 para exteriores
      }));
    } else if (userChoices.type === "INTERIORS") {
      setUserChoices((prevChoices) => ({
        ...prevChoices,
      }));
    }
  }, [userChoices.type]);

  const handleAdvancedFineTuningToggle = () => {
    if (isUserFree()) { // Verifica se o usuário é free
      alert("Esta funcionalidade é apenas para usuários premium.");
      return;
    }

    setUserChoices((prevChoices) => {
      const newState = !prevChoices.isAdvancedFineTuningEnabled;
      console.log("Novo estado de isAdvancedFineTuningEnabled:", newState);
      return { ...prevChoices, isAdvancedFineTuningEnabled: newState };
    });
  };

  const [isInterior, setIsInterior] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(null);
  const [openAccordion1, setOpenAccordion1] = useState(null);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 1040);
    };
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const updateUserChoices = (category, choice) => {
    setUserChoices((prevChoices) => ({
      ...prevChoices,
      [category]: choice,
    }));
  };

  const { i18n } = useTranslation();

  const decodedToken = jwtDecode(token);
  const user = decodedToken.clienteId;

  // Move handleRender outside of convertToJson
  const handleRender = () => {

    let finalConfig;

    const nomeImage = `${user}_${Date.now()}.png`;
    sessionStorage.setItem("nomeImage", nomeImage);

    if (isPromptEnabled && promptInput) {
      // First, fetch the full default geraPrompt structure based on user choices (landscape, portrait, etc.)
      let geraPrompt;

      if (userChoices.precision === "PROFESSIONAL") {
        if (userChoices.imageCropper === "landscape") {
          geraPrompt = geraPromptExteriorLandscapePro(userChoices, user, nomeImage);
        } else if (userChoices.imageCropper === "portrait") {
          geraPrompt = geraPromptExteriorPortraitPro(userChoices, user, nomeImage);
        } else if (userChoices.imageCropper === "square") {
          geraPrompt = geraPromptExteriorSquarePro(userChoices, user, nomeImage);
        }
      } else {
        if (userChoices.imageCropper === "landscape") {
          geraPrompt = geraPromptExteriorLandscapeStandart(userChoices, user, nomeImage);
        } else if (userChoices.imageCropper === "portrait") {
          geraPrompt = geraPromptExteriorPortraitStandart(userChoices, user, nomeImage);
        } else if (userChoices.imageCropper === "square") {
          geraPrompt = geraPromptExteriorSquareStandart(userChoices, user, nomeImage);
        }
      }

      // Now, copy the full default structure of geraPrompt into finalConfig
      finalConfig = { ...geraPrompt };

      // Replace only the "20" node with the custom prompt input
      if (finalConfig["20"] && finalConfig["20"]["inputs"]) {
        finalConfig["20"]["inputs"]["text"] = promptInput.trim();
      } else {
        console.error("Node 20 not found in the prompt structure.");
      }
    } else {
      // Otherwise, use the normal geraPrompt logic
      let geraPrompt;

      if (userChoices.type === "EXTERIORS") {
        if (userChoices.precision === "PROFESSIONAL") {
          if (userChoices.imageCropper === "landscape") {
            geraPrompt = geraPromptExteriorLandscapePro(userChoices, user, nomeImage);
          } else if (userChoices.imageCropper === "portrait") {
            geraPrompt = geraPromptExteriorPortraitPro(userChoices, user, nomeImage);
          } else if (userChoices.imageCropper === "square") {
            geraPrompt = geraPromptExteriorSquarePro(userChoices, user, nomeImage);
          }
        } else {
          if (userChoices.imageCropper === "landscape") {
            geraPrompt = geraPromptExteriorLandscapeStandart(userChoices, user, nomeImage);
          } else if (userChoices.imageCropper === "portrait") {
            geraPrompt = geraPromptExteriorPortraitStandart(userChoices, user, nomeImage);
          } else if (userChoices.imageCropper === "square") {
            geraPrompt = geraPromptExteriorSquareStandart(userChoices, user, nomeImage);
          }
        }
      } else if (userChoices.type === "INTERIORS") {
        if (userChoices.precision === "PROFESSIONAL") {
          if (userChoices.imageCropper === "landscape") {
            geraPrompt = geraPromptInteriorLandscapePro(userChoices, user, nomeImage);
          } else if (userChoices.imageCropper === "portrait") {
            geraPrompt = geraPromptInteriorPortraitPro(userChoices, user, nomeImage);
          } else if (userChoices.imageCropper === "square") {
            geraPrompt = geraPromptInteriorSquarePro(userChoices, user, nomeImage);
          }
        } else {
          if (userChoices.imageCropper === "landscape") {
            geraPrompt = geraPromptInteriorLandscapeStandart(userChoices, user, nomeImage);
          } else if (userChoices.imageCropper === "portrait") {
            geraPrompt = geraPromptInteriorPortraitStandart(userChoices, user, nomeImage);
          } else if (userChoices.imageCropper === "square") {
            geraPrompt = geraPromptInteriorSquareStandart(userChoices, user, nomeImage);
          }
        }
      }

      // Copy the full geraPrompt structure into finalConfig
      finalConfig = { ...geraPrompt };
    }

    // Store the final configuration in sessionStorage
    const saida = finalConfig;

    sessionStorage.setItem("GeraPrompt", JSON.stringify(saida));

  };


  // Keep convertToJson as is
  const convertToJson = () => {
    handleRender();
  };


  useEffect(() => {
    document.title = "Sketch 2 Render - Arcseed AI";
    return () => {
      document.title = "Arcseed AI";
    };
  }, []);

  useEffect(() => {
    setOpenCustomization(false);
    setopenFinetuning(false);
  }, [userChoices.type]);

  const handleAccordionToggle = (accordionId) => {
    setOpenAccordion(openAccordion === accordionId ? null : accordionId);
  };

  const [openCustomization, setOpenCustomization] = useState(false);
  const [openFinetuning, setopenFinetuning] = useState(false);

  const handleAccordionToggle1 = (accordionId) => {
    setOpenAccordion1(openAccordion1 === accordionId ? null : accordionId);
  };

  const renderWallpaperImages = () => {
    const items = [];

    for (let i = 0; i <= Math.ceil(window.innerWidth / 1255) + 3; i++) {
      items.push(
        <img
          alt="Wallpaper not found"
          src={WallpaperGen}
          className="w-auto h-[500px] opacity-60"
        />
      );
    }

    return (
      window.innerWidth < 1024 ?
        (
          <div className="absolute top-0 left-0 flex flex-col max-h-[700px] overflow-hidden">
            <div className="flex">{items}</div>
            <div className="flex">{items}</div>
          </div>
        )
        :
        (
          <div className="absolute top-0 left-0 flex flex-nowrap">{items}</div>
        )
    )
  };

  useEffect(() => {

  }, [userChoices.precision])

  useEffect(() => {
    sessionStorage.removeItem("GeraPrompt");
    sessionStorage.removeItem("croppedImage");
    sessionStorage.removeItem("nomeImage");
    sessionStorage.removeItem("imageUploader");
    sessionStorage.removeItem("imageUploader2");
  }, [])

  useEffect(() => {
    if (userChoices.type === "EXTERIORS") {
      setUserChoices((prevChoices) => ({
        ...prevChoices,
        InputDepthRange: "2"

      }))
    }
    else {
      setUserChoices((prevChoices) => ({
        ...prevChoices,
        InputDepthRange: "3"
      }))
    }

  }, [userChoices.precision])

  const handleTypeChoose = () => {
    if (userChoices.type !== isInterior) {
      setUserChoices((prevChoices) => ({
        ...prevChoices,
        what: null,
        whereExterior: null,
        time: null,
        weather: null,
        style: "Modern",
        colorPalette: null,
        materials: null,
        effects: null,
        visibility: "PUBLIC",
        precision: "STANDARD",
      }));

      switch (userChoices.type) {

        case "INTERIORS":
          setUserChoices(prevChoices => ({
            ...prevChoices,
            AIImmaginationRange: "3",
            ShapeStrengthRange: "2",
            ShapeExposureRange: "2",
            InputDepthRange: "3",
            contourStrength: "3",
            contourExposure: "4",
            DepthExposureRange: "4"
          }));

          break;

        case "EXTERIORS":
          setUserChoices((prevChoices) => ({
            ...prevChoices,
            AIImmaginationRange: "3",
            InputDepthRange: "2",
            ShapeStrengthRange: "2",
            ShapeExposureRange: "2",
            contourStrength: "3",
            contourExposure: "3",
            DepthExposureRange: "4"
          }))

          break;
      }
      setIsInterior(userChoices.type);
      setOpenAccordion(null);
      setOpenAccordion1(null);
    }
    if (userChoices.type === "INTERIORS") {
      return (

        <div>
          <GenericRenderComponent
            updateUserChoices={updateUserChoices}
            renderParamJSONConfig={whatInteriorsJSON}
            isOpen={true} />
          {/* <WhatInterior updateUserChoices={updateUserChoices} /> */}
          <div className="flex justify-center items-center w-full">
            <h1
              onClick={() => setOpenCustomization(!openCustomization)}
              className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
            >
              {i18n.t("CUSTOMIZATION")}
            </h1>
          </div>
          <Accordion isOpen={openCustomization}>
            <div className={`relative ${isUserFree() ? "blur-sm pointer-events-none" : ""}`}>
              {window.innerWidth < 1024 ?
                (
                  <div className="flex flex-col w-full h-[700px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
                    {renderWallpaperImages()}
                    <GenericRenderComponent
                      updateUserChoices={updateUserChoices}
                      renderParamJSONConfig={timeInteriorsJSON}
                      isOpen={(openAccordion === "timeInteriors")}
                      onToggle={() => handleAccordionToggle("timeInteriors")} />
                    {/* <Time
                  isOpen={openAccordion === "timeInterior"}
                  onToggle={() => handleAccordionToggle("timeInterior")}
                  updateUserChoices={updateUserChoices}
                /> */}
                    <div className="mt-2">
                      <GenericRenderComponent
                        updateUserChoices={updateUserChoices}
                        renderParamJSONConfig={styleInteriorsJSON}
                        isOpen={(openAccordion === "styleInteriors")}
                        onToggle={() => handleAccordionToggle("styleInteriors")} />
                      {/* <Style
                  isOpen={openAccordion1 === "styleInterior"}
                  onToggle={() => handleAccordionToggle1("styleInterior")}
                  updateUserChoices={updateUserChoices}
                /> */}
                    </div>
                  </div>
                )
                :
                (
                  <div className="flex w-full h-[500px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
                    {renderWallpaperImages()}
                    <div className="flex flex-col h-[100%] justify-center w-[50vw]">
                      <GenericRenderComponent
                        updateUserChoices={updateUserChoices}
                        renderParamJSONConfig={timeInteriorsJSON}
                        isOpen={(openAccordion === "timeInteriors")}
                        onToggle={() => handleAccordionToggle("timeInteriors")} />
                      {/* <Time
                  isOpen={openAccordion === "timeInterior"}
                  onToggle={() => handleAccordionToggle("timeInterior")}
                  updateUserChoices={updateUserChoices}
                /> */}
                    </div>
                    <div className="h-[300px] left-1/2 z-20 translate-x-1/2 absolute w-[0.0625rem] bg-black hidden lg:inline" />
                    <div className="flex flex-col h-[100%] justify-center w-[50vw]">
                      <GenericRenderComponent
                        updateUserChoices={updateUserChoices}
                        renderParamJSONConfig={styleInteriorsJSON}
                        isOpen={(openAccordion1 === "styleInteriors")}
                        onToggle={() => handleAccordionToggle1("styleInteriors")} />
                      {/* <Style
                  isOpen={openAccordion1 === "styleInterior"}
                  onToggle={() => handleAccordionToggle1("styleInterior")}
                  updateUserChoices={updateUserChoices}
                /> */}
                    </div>
                  </div>
                )}
            </div>
            <ColorPalette updateUserChoices={updateUserChoices} />
            <MaterialWalls updateUserChoices={updateUserChoices} />
          </Accordion>
          <div className="flex flex-col justify-center items-center">
            <h1
              onClick={() => setopenFinetuning(!openFinetuning)}
              className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
            >
              {i18n.t("Fine-tuning")}
            </h1>
          </div>
          <Accordion isOpen={openFinetuning}>
            <div className="my-4">
              <DesignerPersonality
                initialValue={"3"}
                updateUserChoices={updateUserChoices} />
            </div>
            {/* Título ADVANCED */}
            <div className="flex flex-col justify-center items-center">
              <h1
                onClick={() => setopenFinetuning(!openFinetuning)}
                className={`
      font-title cursor-pointer transition-all ease-in-out 
      duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
      border-solid border-black leading-[99%]`}
              >
                {t("FINE-TUNING ADVANCED")}
              </h1>
              <div className="mt-4">
                <label className="flex items-center space-x-2">
                  <span className="mr-2 font-title font-bold">
                    {t("Enable Advanced Fine-Tuning")}
                  </span>
                  <input
                    type="checkbox"
                    checked={userChoices.isAdvancedFineTuningEnabled}
                    onChange={handleAdvancedFineTuningToggle}
                    className="form-checkbox h-5 w-5 text-white-600"
                  />
                </label>
              </div>
            </div>



            {userChoices.isAdvancedFineTuningEnabled && isUserFree() && (
              <div className="text-red-600 font-bold text-xl mt-4 text-center">
                {t("FINE-TUNING PREMIUM HAVE A MANY OTHER FILTERS")},
                <a href="https://www.arcseedai.com/PlansPage/ENTHUSIAST" target="_blank" className="text-black hover:text-gray-300">
                  {t(" CLICK HERE ")}
                </a>
                {t(" TO UNLOCK IT.")}
              </div>
            )}

            {userChoices.isAdvancedFineTuningEnabled && (
              <div className={`relative ${isUserFree() ? "blur-sm pointer-events-none" : ""}`}>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-6">
                  {/* Coluna 1 */}
                  <div className="flex flex-col space-y-6">
                    <Originality
                      initialValue={"1.0"}
                      updateUserChoices={updateUserChoices}
                      OriginalityToogle={true}
                    />

                    <div className="justify-center flex">
                      <ContourStrength
                        updateUserChoices={updateUserChoices}
                        initialValue={userChoices.contourStrength}
                      />
                    </div>

                    <AIImmaginationRange
                      initialValue={userChoices.AIImmaginationRange}
                      updateUserChoices={updateUserChoices}
                    />
                  </div>

                  {/* Coluna 2 */}
                  <div className="flex flex-col space-y-6">
                    <InputDepth
                      initialValue={userChoices.InputDepthRange}
                      updateUserChoices={updateUserChoices}
                    />

                    <div className="justify-center flex">
                      <ContourExposure
                        initialValue={userChoices.contourExposure}
                        updateUserChoices={updateUserChoices}
                      />
                    </div>

                    <div className="justify-center flex">
                      <DepthExposure
                        initialValue={userChoices.DepthExposureRange}
                        updateUserChoices={updateUserChoices}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Accordion>
          <div className={`relative ${isUserFree() ? "blur-sm pointer-events-none" : ""}`}>
            <Visibility updateUserChoices={updateUserChoices} />
            <Precision
              updateUserChoices={updateUserChoices}
              standardColors={standardColors}
              professionalColors={professionalColors}
            />
          </div>
          {isUserFree() && (
            <div className="text-red-600 font-bold text-2xl mt-4 text-center">
              {t("SOME FEATURES ARE ONLY AVAILABLE FOR PREMIUM USERS,")},
              <a href="https://www.arcseedai.com/PlansPage/ENTHUSIAST" target="_blank" className="text-black hover:text-gray-300">
                {t(" CLICK HERE ")}
              </a>
              {t(" TO UNLOCK IT.")}
            </div>
          )}

          <RenderButton
            onRenderButtonClick={handleRender}  // Reference the function here
            userChoices={userChoices}
            onMoedasUpdate={handleMoedasUpdate}
            isPromptEnabled={isPromptEnabled}
          />
        </div>
      );
    }

    else if (userChoices.type === "PROMPT") {  // Check if Prompt Toggle is enabled
      return (
        <div>

          {/* Accordions for Prompt Toggle, similar to INTERIORS */}
          <Accordion isOpen={openCustomization}>
            {window.innerWidth < 1024 ? (
              <div className="flex w-full h-[700px] bg-[#f3f4f6] flex-col max-w-screen justify-center relative items-center">
                {renderWallpaperImages()}
                <div className="mb-2">
                  <GenericRenderComponent
                    updateUserChoices={updateUserChoices}
                    renderParamJSONConfig={timeInteriorsJSON}  // Adjust based on Prompt logic
                    isOpen={openAccordion === "time"}
                    onToggle={() => handleAccordionToggle("time")}
                  />
                </div>
                <div>
                  <GenericRenderComponent
                    updateUserChoices={updateUserChoices}
                    renderParamJSONConfig={styleInteriorsJSON}  // Adjust based on Prompt logic
                    isOpen={openAccordion === "style"}
                    onToggle={() => handleAccordionToggle("style")}
                  />
                </div>
              </div>
            ) : (
              <div className="flex w-full h-[500px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
                {renderWallpaperImages()}
                <div className="w-1/2">
                  <GenericRenderComponent
                    updateUserChoices={updateUserChoices}
                    renderParamJSONConfig={timeInteriorsJSON}
                    isOpen={openAccordion === "time"}
                    onToggle={() => handleAccordionToggle("time")}
                  />
                </div>
                <div className="h-[300px] left-1/2 z-20 translate-x-1/2 absolute w-[0.0625rem] bg-black hidden lg:inline" />
                <div className="w-1/2">
                  <GenericRenderComponent
                    updateUserChoices={updateUserChoices}
                    renderParamJSONConfig={styleInteriorsJSON}
                    isOpen={openAccordion1 === "style"}
                    onToggle={() => handleAccordionToggle1("style")}
                  />
                </div>
              </div>
            )}

          </Accordion>
          <div className="flex flex-col justify-center items-center">
            <h1
              onClick={() => setopenFinetuning(!openFinetuning)}
              className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
            >
              {i18n.t("Fine-tuning")}
            </h1>
          </div>
          <Accordion isOpen={openFinetuning}>
            <div className="my-4">
              <DesignerPersonality
                initialValue={"3"}
                updateUserChoices={updateUserChoices} />
            </div>
            {/* Título ADVANCED */}
            <div className="flex flex-col justify-center items-center">
              <h1
                onClick={() => setopenFinetuning(!openFinetuning)}
                className={`
      font-title cursor-pointer transition-all ease-in-out 
      duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
      border-solid border-black leading-[99%]`}
              >
                {t("FINE-TUNING ADVANCED")}
              </h1>
              <div className="mt-4">
                <label className="flex items-center space-x-2">
                  <span className="mr-2 font-title font-bold">
                    {t("Enable Advanced Fine-Tuning")}
                  </span>
                  <input
                    type="checkbox"
                    checked={userChoices.isAdvancedFineTuningEnabled}
                    onChange={handleAdvancedFineTuningToggle}
                    className="form-checkbox h-5 w-5 text-white-600"
                  />
                </label>
              </div>
            </div>



            {userChoices.isAdvancedFineTuningEnabled && isUserFree() && (
              <div className="text-red-600 font-bold text-xl mt-4 text-center">
                {t("FINE-TUNING PREMIUM HAVE A MANY OTHER FILTERS")},
                <a href="https://www.arcseedai.com/PlansPage/ENTHUSIAST" target="_blank" className="text-black hover:text-gray-300">
                  {t(" CLICK HERE ")}
                </a>
                {t(" TO UNLOCK IT.")}
              </div>
            )}

            {userChoices.isAdvancedFineTuningEnabled && (
              <div className={`relative ${isUserFree() ? "blur-sm pointer-events-none" : ""}`}>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-6">
                  {/* Coluna 1 */}
                  <div className="flex flex-col space-y-6">
                    <Originality
                      initialValue={"1.0"}
                      updateUserChoices={updateUserChoices}
                      OriginalityToogle={true}
                    />

                    <div className="justify-center flex">
                      <ContourExposure
                        initialValue={userChoices.contourExposure}
                        updateUserChoices={updateUserChoices}
                      />
                    </div>

                    <AIImmaginationRange
                      initialValue={userChoices.AIImmaginationRange}
                      updateUserChoices={updateUserChoices}
                    />
                  </div>

                  {/* Coluna 2 */}
                  <div className="flex flex-col space-y-6">
                    <div className="justify-center flex">
                      <DepthExposure
                        updateUserChoices={updateUserChoices}
                        initialValue={userChoices.DepthExposureRange}
                      />
                    </div>

                    <InputDepth
                      initialValue={userChoices.InputDepthRange}
                      updateUserChoices={updateUserChoices}
                    />

                    <div className="justify-center flex">
                      <ContourStrength
                        updateUserChoices={updateUserChoices}
                        initialValue={userChoices.contourStrength}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Accordion>

          <Visibility updateUserChoices={updateUserChoices} />
          <Precision
            updateUserChoices={updateUserChoices}
            standardColors={standardColors}
            professionalColors={professionalColors}
          />

          {/* Render Button */}
          <RenderButton
            onRenderButtonClick={handleRender}  // Reference the function here
            userChoices={userChoices}
            onMoedasUpdate={handleMoedasUpdate}
            isPromptEnabled={isPromptEnabled}
          />
        </div>
      );
    }





    else if (userChoices.type === "EXTERIORS") {
      return (
        <div className="overflow-hidden">
          {/* <Floors updateUserChoices={updateUserChoices} /> */}
          <GenericRenderComponent
            updateUserChoices={updateUserChoices}
            renderParamJSONConfig={whatExteriorsJSON}
            isOpen={true} />
          {/* <WhatExterior updateUserChoices={updateUserChoices} /> */}
          <div className="flex justify-center items-center w-full">
            <h1
              onClick={() => setOpenCustomization(!openCustomization)}
              className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
            >
              {i18n.t("CUSTOMIZATION")}
            </h1>
          </div>
          <Accordion isOpen={openCustomization}>
            <div className={`relative ${isUserFree() ? "blur-sm pointer-events-none" : ""}`}>
              {window.innerWidth < 1024 ?
                (
                  <div
                    id="horizontal-div"
                    className="flex flex-col w-full h-[700px] bg-[#f3f4f6] max-w-screen justify-center relative items-center"
                  >
                    {renderWallpaperImages()}
                    {/* <div className="h-[300px] left-1/2 z-20 translate-x-1/2 absolute w-[0.0625rem] bg-black hidden lg:inline" /> */}
                    <div className="mb-2">
                      <GenericRenderComponent
                        updateUserChoices={updateUserChoices}
                        renderParamJSONConfig={timeExteriorsJSON}
                        isOpen={(openAccordion === "timeExteriors")}
                        onToggle={() => handleAccordionToggle("timeExteriors")}
                      />
                    </div>
                    {/* <Time
                  isOpen={openAccordion === "timeExterior"}
                  onToggle={() => handleAccordionToggle("timeExterior")}
                  updateUserChoices={updateUserChoices}
                /> */}
                    <div className="mb-2">
                      <GenericRenderComponent
                        updateUserChoices={updateUserChoices}
                        renderParamJSONConfig={whereExteriorsJSON}
                        isOpen={(openAccordion === "whereExteriors")}
                        onToggle={() => handleAccordionToggle("whereExteriors")}
                      />
                    </div>
                    {/* <Where
                  isOpen={openAccordion === "whereExterior"}
                  onToggle={() => handleAccordionToggle("whereExterior")}
                  updateUserChoices={updateUserChoices}
                /> */}
                    <div className="mb-2">
                      <GenericRenderComponent
                        updateUserChoices={updateUserChoices}
                        renderParamJSONConfig={weatherExteriorsJSON}
                        isOpen={(openAccordion === "weatherExteriors")}
                        onToggle={() => handleAccordionToggle("weatherExteriors")}
                      />
                    </div>
                    {/* <Weather
                  isOpen={openAccordion1 === "weatherExterior"}
                  onToggle={() => handleAccordionToggle1("weatherExterior")}
                  updateUserChoices={updateUserChoices}
                /> */}
                    <div className="mb-2">
                      <GenericRenderComponent
                        updateUserChoices={updateUserChoices}
                        renderParamJSONConfig={styleExteriorsJSON}
                        isOpen={(openAccordion === "styleExteriors")}
                        onToggle={() => handleAccordionToggle("styleExteriors")}
                      />
                    </div>
                    {/* <Style
                  isOpen={openAccordion1 === "styleExterior"}
                  onToggle={() => handleAccordionToggle1("styleExterior")}
                  updateUserChoices={updateUserChoices}
                /> */}
                  </div>
                )
                :
                (
                  <div
                    id="horizontal-div"
                    className="flex w-full h-[500px] bg-[#f3f4f6] max-w-screen justify-center relative items-center"
                  >
                    {renderWallpaperImages()}
                    <div className="h-[300px] left-1/2 z-20 translate-x-1/2 absolute w-[0.0625rem] bg-black hidden lg:inline" />
                    <div className="flex flex-col h-[100%] justify-center w-[50vw]">
                      <div className="mb-2">
                        <GenericRenderComponent
                          updateUserChoices={updateUserChoices}
                          renderParamJSONConfig={timeExteriorsJSON}
                          isOpen={(openAccordion === "timeExteriors")}
                          onToggle={() => handleAccordionToggle("timeExteriors")}
                        />
                      </div>
                      {/* <Time
                  isOpen={openAccordion === "timeExterior"}
                  onToggle={() => handleAccordionToggle("timeExterior")}
                  updateUserChoices={updateUserChoices}
                /> */}
                      <GenericRenderComponent
                        updateUserChoices={updateUserChoices}
                        renderParamJSONConfig={whereExteriorsJSON}
                        isOpen={(openAccordion === "whereExteriors")}
                        onToggle={() => handleAccordionToggle("whereExteriors")}
                      />
                      {/* <Where
                  isOpen={openAccordion === "whereExterior"}
                  onToggle={() => handleAccordionToggle("whereExterior")}
                  updateUserChoices={updateUserChoices}
                /> */}
                    </div>
                    <div className="flex flex-col justify-center w-1/2">
                      <div className="mb-2">
                        <GenericRenderComponent
                          updateUserChoices={updateUserChoices}
                          renderParamJSONConfig={weatherExteriorsJSON}
                          isOpen={(openAccordion1 === "weatherExteriors")}
                          onToggle={() => handleAccordionToggle1("weatherExteriors")}
                        />
                      </div>
                      {/* <Weather
                  isOpen={openAccordion1 === "weatherExterior"}
                  onToggle={() => handleAccordionToggle1("weatherExterior")}
                  updateUserChoices={updateUserChoices}
                /> */}
                      <GenericRenderComponent
                        updateUserChoices={updateUserChoices}
                        renderParamJSONConfig={styleExteriorsJSON}
                        isOpen={(openAccordion1 === "styleExteriors")}
                        onToggle={() => handleAccordionToggle1("styleExteriors")}
                      />
                      {/* <Style
                  isOpen={openAccordion1 === "styleExterior"}
                  onToggle={() => handleAccordionToggle1("styleExterior")}
                  updateUserChoices={updateUserChoices}
                /> */}
                    </div>
                  </div>
                )}
            </div>
            <ColorPalette updateUserChoices={updateUserChoices} />
            <MaterialWalls updateUserChoices={updateUserChoices} />
          </Accordion>
          <div className="flex justify-center items-center w-full">
            <h1
              onClick={() => setopenFinetuning(!openFinetuning)}
              className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
            >
              {i18n.t("Fine-tuning")}
            </h1>
          </div>
          <Accordion isOpen={openFinetuning}>
            <div>

              {/* Filtros com slider, sempre exibido */}
              <div className="my-4">
                <DesignerPersonality
                  initialValue={"3"}
                  updateUserChoices={updateUserChoices} />
              </div>
            </div>
            {/* Título ADVANCED */}
            <div className="flex flex-col justify-center items-center">
              <h1
                onClick={() => setopenFinetuning(!openFinetuning)}
                className={`
      font-title cursor-pointer transition-all ease-in-out 
      duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
      border-solid border-black leading-[99%]`}
              >
                {t("FINE-TUNING ADVANCED")}
              </h1>
              <div className="mt-4">
                <label className="flex items-center space-x-2">
                  <span className="mr-2 font-title font-bold">
                    {t("Enable Advanced Fine-Tuning")}
                  </span>
                  <input
                    type="checkbox"
                    checked={userChoices.isAdvancedFineTuningEnabled}
                    onChange={handleAdvancedFineTuningToggle}
                    className="form-checkbox h-5 w-5 text-white-600"
                  />
                </label>
              </div>
            </div>



            {userChoices.isAdvancedFineTuningEnabled && isUserFree() && (
              <div className="text-red-600 font-bold text-xl mt-4 text-center">
                {t("FINE-TUNING PREMIUM HAVE A MANY OTHER FILTERS")},
                <a href="https://www.arcseedai.com/PlansPage/ENTHUSIAST" target="_blank" className="text-black hover:text-gray-300">
                  {t(" CLICK HERE ")}
                </a>
                {t(" TO UNLOCK IT.")}
              </div>
            )}

            {userChoices.isAdvancedFineTuningEnabled && (
              <div className={`relative ${isUserFree() ? "blur-sm pointer-events-none" : ""}`}>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-6">
                  {/* Coluna 1 */}
                  <div className="flex flex-col space-y-6">
                    <Originality
                      initialValue={"1.0"}
                      updateUserChoices={updateUserChoices}
                      OriginalityToogle={true}
                    />

                    <div className="justify-center flex">
                      <ContourStrength
                        updateUserChoices={updateUserChoices}
                        initialValue={userChoices.contourStrength}
                      />
                    </div>

                    <AIImmaginationRange
                      initialValue={userChoices.AIImmaginationRange}
                      updateUserChoices={updateUserChoices}
                    />
                  </div>

                  {/* Coluna 2 */}
                  <div className="flex flex-col space-y-6">
                    <InputDepth
                      initialValue={userChoices.InputDepthRange}
                      updateUserChoices={updateUserChoices}
                    />

                    <div className="justify-center flex">
                      <ContourExposure
                        initialValue={userChoices.contourExposure}
                        updateUserChoices={updateUserChoices}
                      />
                    </div>


                    <div className="justify-center flex">
                      <DepthExposure
                        updateUserChoices={updateUserChoices}
                        initialValue={userChoices.DepthExposureRange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Accordion>
          <div className={`relative ${isUserFree() ? "blur-sm pointer-events-none" : ""}`}>
            <Visibility updateUserChoices={updateUserChoices} />
            <Precision
              updateUserChoices={updateUserChoices}
              standardColors={standardColors}
              professionalColors={professionalColors}
            />
          </div>
          {isUserFree() && (
            <div className="text-red-600 font-bold text-2xl mt-4 text-center">
              {t("SOME FEATURES ARE ONLY AVAILABLE FOR PREMIUM USERS,")},
              <a href="https://www.arcseedai.com/PlansPage/ENTHUSIAST" target="_blank" className="text-black hover:text-gray-300">
                {t(" CLICK HERE ")}
              </a>
              {t(" TO UNLOCK IT.")}
            </div>
          )}
          <RenderButton
            onRenderButtonClick={handleRender}
            userChoices={userChoices}
            onMoedasUpdate={handleMoedasUpdate}
            isPromptEnabled={isPromptEnabled}
          />
        </div>
      );
    } else {
      return (
        <div className="flex w-full max-w-screen my-2 justify-center">
          <p className="font-paragraph px-6 lg:px-0 text-2xl">
            {i18n.t("Tip: select the ambient type to access the other inputs!")}
          </p>
        </div>
      );
    }

  };

  useEffect(() => {

  }, [userChoices]);  // This will log whenever userChoices changes

  return (
    <div className="overflow-hidden relative">
      <NewPopUpComponent />
      <Navbar
        userChoices={userChoices}
        title={t("Sketch 2 Render ")}
        description={i18n.t(
          "Create amazing renderings without any input image, just select the options and create mind-blowing possibilities! If you are an advanced user, click here for the advanced toolset. All generative pages will be in one place for fast editing and access."
        )}
        showPoints={true}
        moedas={moedas}
      />
      <div
        style={{
          marginTop: navbarHeight + 20
        }}
      >
        <ImageUploader
          updateUserChoices={updateUserChoices}
          subtitle={
            "You can take a screenshot (PrtScr on your keyboard) and paste it here with Ctrl+V, to crop it below."
          }
          title={"Drop the sketch or screenshot here"}
        />
      </div>
      <ImageCropper
        image={sessionStorage.getItem("imageUploader")}
        updateUserChoices={updateUserChoices}
      />

      {/* Encapsulate Prompt in a light gray box with fixed width and animation */}
      <div
        className={`p-10  mt-10 max-w-4xl mx-auto transition-all duration-500 ${isPromptEnabled ? 'bg-gray-100' : 'bg-black'}`}
      >
        {/* Prompt Field */}
        <div className="flex flex-col justify-center items-center">
          <label
            htmlFor="promptInput"
            className={`font-bold font-title flex items-center text-3xl transition-all duration-500 ${isPromptEnabled ? 'text-black' : 'text-white'}`}
          >
            PROMPT
            <div className="w-[1px] h-[1px] relative">
              <InfoBox
                customClass="absolute top-1/2 -translate-y-1/2 left-2 text-black"
                text={t("Prompts give you more control over the AI, They should be in English and have a 120 characters limit.")}
              />
            </div>
          </label>

          <input
            id="promptInput"
            type="text"
            maxLength={120}
            value={promptInput}
            onChange={handleInputChange}
            disabled={!isPromptEnabled}  // Disable when the toggle is off
            className={`border-b-4 transition-all duration-500 ${isPromptEnabled ? 'border-black text-black bg-transparent' : 'border-white text-white bg-black'} mt-5 p-2 focus:outline-none w-full`}
            placeholder={t("Enter your Prompt here...")}
          />

          {/* Add a Translate Button */}
          <button
            onClick={handleTranslateClick}
            className={`bg-gray-500 hover:bg-gray-700 text-white font-bold mt-9 py-2 px-4 ${!isPromptEnabled || promptInput === previousTranslatedText || isTranslating ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={!isPromptEnabled || promptInput === previousTranslatedText || isTranslating}  // Disable based on conditions
            aria-label={t("Translate prompt to English")}
          >
            {isTranslating ? t("TRANSLATING...") : t("TRANSLATE TO ENGLISH")}
          </button>


          {/* Error message */}
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        </div>

        {/* Centered Toggle with Bigger Size */}
        <div className="mt-10 flex flex-col justify-center items-center">
          <label
            className={`block text-md mb-5 font-bold transition-all duration-500 ${isPromptEnabled ? 'text-black' : 'text-white'}`}
          >
            {t("CLICK TO TURN THE PROMPT TOOL ON!")}<br /> {t("The Prompt has to be in english, click TRANSLATE TO ENGLISH if you need a translation.")}
          </label>

          {/* Use the PromptToggleOrMessage function here */}
          <PromptToggleOrMessage
            isPromptEnabled={isPromptEnabled}
            handlePromptToggle={handlePromptToggle}
            t={t}
          />
        </div>
      </div>

      {/* Other Options Section */}
      <div className={`mt-[${navbarHeight}px]`}></div>
      <div className="flex lg:flex-row flex-col w-full py-8 items-center justify-center max-w-screen">
        <div
          className="w-1/3"
          style={{
            opacity: isPromptEnabled ? 0 : 1,
            transition: 'opacity 0.5s ease-in-out',
            visibility: isPromptEnabled ? 'hidden' : 'visible',
          }}
        >
          <AIModels verticalSet={isSmallScreen} updateUserChoices={updateUserChoices} />
        </div>

        <div className="w-[0.06rem] h-[19rem] bg-black hidden lg:inline"></div>

        <div className="w-1/3">
          <Seed updateUserChoices={updateUserChoices} />
        </div>

        <div className="w-[0.06rem] h-[19rem] bg-black hidden lg:inline"></div>

        <div className="w-1/3">
          <Type
            userChoice={userChoices.type}
            verticalSet={isSmallScreen}
            className="mt-8"
            updateUserChoices={updateUserChoices}
          />
        </div>
      </div>

      {handleTypeChoose()}
      <Footer footerVersion="light-footer" renderTitle="Sketch 2 Render" />
    </div>
  );

}


import React, { useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import InfoBox from "../InfoBox";
import { useTranslation } from "react-i18next";

export default function Variation({ updateUserChoices }) {
  const [variationValue, setVariationValue] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    updateUserChoices("variation", variationValue);
  }, [variationValue, updateUserChoices]);

  const increment = () => {
    setVariationValue((prev) => (prev < 99 ? prev + 1 : prev));
  };

  const decrement = () => {
    setVariationValue((prev) => (prev > 0 ? prev - 1 : prev));
  };

  return (
    <div className="flex items-center mt-4 space-x-1 relative"> {/* Alterado de space-x-4 para space-x-1 */}
      <button
        onClick={decrement}
        className="text-3xl text-black p-2 hover:text-gray-700"
      >
        <FaChevronLeft />
      </button>
      <span className="text-4xl font-bold w-16 text-center">{variationValue}</span>
      <button
        onClick={increment}
        className="text-3xl text-black p-2 hover:text-gray-700"
      >
        <FaChevronRight />
      </button>
      <InfoBox
        customClass="absolute top-1/2 -translate-y-1/2 left-full ml-2"
        text={t(
          "Each variation represents a numerical value that will create a unique image at the end of the process, ex: 1, 2, 5628, 55147."
        )}
      />
    </div>
  );
}
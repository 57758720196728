import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import InfoBox from "../InfoBox";

export default function ShapeExposure({ updateUserChoices, initialValue }) {
  const [shapeExposure, setShapeExposure] = useState(initialValue || "1"); // Usando initialValue
  const { t } = useTranslation();

  const handleShapeExposureChange = (event) => {
    const value = event.target.value;
    setShapeExposure(value);
    updateUserChoices("ShapeExposureRange", value);
  };

  return (
    <div className="items-center flex flex-col mb-4">
      <div className="flex">
        <h1 className="font-title lg:justify-center flex items-center font-bold py-8 lg:py-2 lg:mb-2">
          {t("SHAPE EXPOSURE")}
          <div className="w-[0.01px] h-[0.01px] relative">
            <InfoBox
              customClass={"absolute left-1 top-1/2 -translate-y-1/2"}
              text={t("Stronger the exposure more the AI will let the shape participate on the final render.")}
            />
          </div>
        </h1>
      </div>
      <div className="flex justify-between max-w-screen items-center w-[93vw] mb-4 lg:w-[35rem]">
        <span className="text-lg font-title w-7 h-7 bg-black rounded-full text-white">1</span>
        <span className="text-lg font-title w-7 h-7 bg-black rounded-full text-white">2</span>
        <span className="text-lg font-title w-7 h-7 bg-black rounded-full text-white">3</span>
        <span className="text-lg font-title w-7 h-7 bg-black rounded-full text-white">4</span>
        <span className="text-lg font-title w-7 h-7 bg-black rounded-full text-white">5</span>
      </div>
      <input
        type="range"
        min="1"
        max="5"
        value={shapeExposure}
        onChange={handleShapeExposureChange}
        className="lg:w-[35rem] w-[90vw] appearance-none h-1 rounded-full bg-black opacity-85 outline-none transition-all duration-300"
      />
      <p className="font-paragraph text-xl mt-3 mb-1 lg:text-justify">
        {t("How long the AI should hold to the original image's form")}
      </p>
    </div>
  );
}
import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';


const ImagePainter = ({ imageURL }) => {
  const canvasRef = useRef(null);
  const [drawingMode, setDrawingMode] = useState(false);
  const [intensity, setIntensity] = useState(128);
  const [isDrawing, setIsDrawing] = useState(false);
  const [ctx, setCtx] = useState(null);
  const { t } = useTranslation(); 

  useEffect(() => {
    if (drawingMode) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      setCtx(context);

      // Carregar a imagem no canvas
      const img = new Image();
      img.src = `data:image/png;base64,${imageURL}`;
      img.onload = () => {
        const maxWidth = 800; // Largura máxima do canvas
        const maxHeight = 600; // Altura máxima do canvas

        let width = img.width;
        let height = img.height;

        // Redimensionar proporcionalmente
        if (width > maxWidth || height > maxHeight) {
          const aspectRatio = width / height;
          if (aspectRatio > 1) {
            width = maxWidth;
            height = maxWidth / aspectRatio;
          } else {
            height = maxHeight;
            width = maxHeight * aspectRatio;
          }
        }

        canvas.width = width;
        canvas.height = height;
        context.drawImage(img, 0, 0, width, height);
      };
    }
  }, [drawingMode, imageURL]);

  const getCanvasCoordinates = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    return { x, y };
  };

  const startDrawing = (e) => {
    if (!drawingMode || !ctx) return;

    const { x, y } = getCanvasCoordinates(e);
    ctx.beginPath();
    ctx.moveTo(x, y);
    setIsDrawing(true);
  };

  const draw = (e) => {
    if (!isDrawing || !ctx) return;

    const { x, y } = getCanvasCoordinates(e);
    ctx.strokeStyle = `rgb(${intensity}, ${intensity}, ${intensity})`;
    ctx.lineWidth = 25;
    ctx.lineCap = 'round';
    ctx.lineTo(x, y);
    ctx.stroke();
  };

  const stopDrawing = () => {
    if (!drawingMode || !ctx) return;

    ctx.closePath();
    setIsDrawing(false);
  };

  return (
    <div className="flex flex-col items-center">
      {drawingMode && (
        <div>
          <canvas
            ref={canvasRef}
            onMouseDown={startDrawing}
            onMouseMove={draw}
            onMouseUp={stopDrawing}
            onMouseLeave={stopDrawing}
            style={{
              border: '1px solid black',
              cursor: 'crosshair',
              maxWidth: '100%',
              height: 'auto',
              display: 'block',
            }}
          ></canvas>
        </div>
      )}
      <button
        className="sm:text-[3.8vw] md:text-[2vw] lg:text-[1.8vw] border-2 border-black border-solid p-2 mt-2 ease-in-out duration-300 transition-colors 
    bg-[#80d5ff] bg-opacity-70 text-black hover:bg-[#80d5ff] hover:bg-opacity-100 hover:text-black hover:cursor-pointer font-title"
        onClick={() => setDrawingMode(!drawingMode)}
      >
        {drawingMode ? t('Disable Ipaint') : t('Enable Ipaint')}
      </button>
      {drawingMode && (
        <div style={{ marginTop: '10px' }}>
          <label htmlFor="intensity">Intensidade: </label>
          <input
            id="intensity"
            type="range"
            min="0"
            max="128"
            value={intensity}
            style={{
              direction: 'rtl',
              WebkitAppearance: 'none',
              width: '100%',
              height: '8px',
              background: `rgb(${intensity}, ${intensity}, ${intensity}`,
              borderRadius: '5px',
              outline: 'none',
              cursor: 'pointer',
            }}
            onChange={(e) => setIntensity(Number(e.target.value))}
          />
        </div>
      )}
    </div>
  );
};

export default ImagePainter;

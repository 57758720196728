import { React, useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { add_Back, add_Front } from "../../address";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const InviteLinkBox = () => {
  const [inviteLink, setInviteLink] = useState('');
  const [copied, setCopied] = useState(false);

  // Fetch para buscar o link de convite ao montar o componente
  useEffect(() => {
    const linkAssociados = localStorage.getItem("UserAffiliateToken");
    if (linkAssociados) {
      setInviteLink(linkAssociados);
    }
    const token = Cookies.get("token");
    const decodedToken = jwtDecode(token);
    const userId = decodedToken.clienteId;
    const fetchInviteLink = async () => {
      try {
        const response = await fetch(`${add_Back}/affiliate/generate/${userId}`, {
          method: 'GET',
          headers: {
            Authorization: `${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          // Extrai somente o token da URL
          const url = new URL(data.link);
          const extractedToken = url.searchParams.get("token");
          localStorage.setItem("UserAffiliateToken", extractedToken);
          setInviteLink(`${add_Front}/Register?token=${extractedToken}`);
        } else {
          console.error('Erro ao buscar o link de convite');
        }
      } catch (error) {
        console.error('Erro na requisição:', error);
      }
    };

    fetchInviteLink();
  }, []); // [] para rodar apenas ao montar o componente


  const copyToClipboard = () => {
    navigator.clipboard.writeText(inviteLink).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reseta a mensagem após 2 segundos
    });
  };

  return (
    <div className="">
      <h3 className="font-paragraph text-xl 
                          py-[3vh] px-[2vw] 
                          border-slate-300 border 
                          bg-slate-200 lg:text-[130%] text-[150%] cursor-pointer 
                          ease-in-out duration-300 transition-colors ">
                            <Trans i18nKey="Your Invite Link">Your Invite Link</Trans></h3>
      <div className="flex items-center">
        <input
          type="text"
          value={inviteLink}
          readOnly
          className="font-paragraph text-xl 
                          py-[3vh] px-[2vw] 
                          border-slate-300 border 
                          bg-slate-200 lg:text-[130%] text-[150%] cursor-pointer 
                          ease-in-out duration-300 transition-colors"
        />
        <button
          onClick={copyToClipboard}
          className=" rounded-r-2xl px-4 py-2"
        >
          <Trans>
          {copied ? "Copied!" : "Copy"}
          </Trans>
        </button>
      </div>
    </div>
  );
};

export default InviteLinkBox;

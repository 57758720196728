import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import InfoBox from "../InfoBox";

export default function DesignerPersonality({ updateUserChoices, initialValue }) {
  const [designerPersonality, setDesignerPersonality] = useState(initialValue || "1");
  const { t } = useTranslation();

  const handleDesignerPersonalityChange = (event) => {
    const value = event.target.value;
    setDesignerPersonality(value);
    updateUserChoices("FineTuninAll", value);
  };

  return (
    <div className="flex flex-col items-center">
      <div className="flex items-center gap-2">
        <h1 className="font-title font-bold py-8 lg:py-2 lg:mb-2 text-center">
          {t("AI DESIGNER'S PERSONALITY")}
        </h1>
        <InfoBox
          customClass="relative top-1/2 -translate-y-1/2"
          text={t("Valor fixo para usuários free.")}
        />
      </div>

      {/* Contêiner das opções com espaçamento corrigido */}
      <div className="flex justify-center gap-2 w-full mb-4">
        {["Dreamy", "Creative", "Balanced", "Focused", "Strict"].map((trait, index) => (
          <span
            key={index}
            className="text-lg font-title min-w-[6rem] px-4 h-10 bg-black text-white 
                      flex items-center justify-center border border-white rounded-full 
                      whitespace-nowrap text-center"
          >
            {trait}
          </span>
        ))}
      </div>

      {/* Input range */}
      <input
        type="range"
        min="1"
        max="5"
        value={designerPersonality}
        onChange={handleDesignerPersonalityChange}
        className="w-[90%] lg:w-[34rem] appearance-none h-1 bg-black rounded-full opacity-85 transition-all duration-300"
      />

      <p className="font-paragraph text-xl mt-3 mb-1 text-center">
        {t("Define the personality of the AI designer for the final output.")}
      </p>
    </div>
  );
}

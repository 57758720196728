import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState } from 'react';
import ScrollToTop from './ScrollToTop.jsx'; 
import IndexPage from './IndexPage.jsx';
import Login from './components/LoginComponentes/Login.jsx';
import Register from './components/LoginComponentes/Register.jsx';
import BrainstormGen from './render-pages/BrainstormRenderPage.jsx';
import ReRenderPage from "./render-pages/ReRenderPage.jsx"; 
import CanvasAlchemyPage from './render-pages/CanvasAlchemyPage.jsx';
import ReDecoratePage from './render-pages/ReDecoratePage.jsx';
import FloorPlannerPage from './render-pages/FloorPlannerPage.jsx';
import Sketch2RenderInteriorPage from './render-pages/Sketch2RenderInteriorPage.jsx';
import CommunityGallery from './components/Gallery/CommunityGallery.js';
import PersonalGallery from './components/PersonalGallery/PersonalGallery.js';
import ForgotPasswordEmailInput from './components/LoginComponentes/ForgotPasswordEmailInput.jsx';
import ForgotPasswordChange from './components/LoginComponentes/ForgotPasswordChange.jsx';
// import PaymentForms from './components/PaymentComponents/PaymentForms.jsx';
// import ChangeInfo from './components/LoginComponentes/ChangeInfo.jsx';
import Privacy from './components/DocumentationComponents/Privacy.jsx';
import Terms from './components/DocumentationComponents/Terms.jsx';
import Return from './components/PaymentComponents/Return.jsx';
import MoedasExtra from './components/PaymentComponents/MoedasExtra.jsx';
import Thankspage from './components/PaymentComponents/Thankspage.jsx';
import ThankspageMoedas from './components/PaymentComponents/ThankspageMoedas.jsx';
import Dashboard from "./dashboard/Dashboard.jsx";
import PlansPage from './components/PlanPageComponents/PlansPage.jsx';
import InstallYourStudio from './components/InStudio/InstallYourStudio.jsx';
import MakeYourAi from './components/InStudio/MakeYourAi.jsx';
import ThanksPageRegister from './components/ThanksPageRegister.jsx';
import WaitingConfirmation from './components/LoginComponentes/WaitingConfirmation.jsx'
import TestingGround from './components/testing.jsx'

export const Context = React.createContext();

function App() {
  const [warningMessage, setWarningMessage] = useState(null);

  console.warn = () => {};
  console.error = () => {};
  return (
    <Context.Provider value={[warningMessage, setWarningMessage]}>
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path={`/`} element={<IndexPage />} />
          <Route path={`/:gotofooter`} element={<IndexPage />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/testing" element={<TestingGround />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/BrainstormGen" element={<BrainstormGen />} />
          <Route path="/CanvasAlchemy" element={<CanvasAlchemyPage />} />
          <Route path="/Decorate" element={<ReDecoratePage />} />
          <Route path="/ReRender" element={<ReRenderPage />} />
          <Route path="/Sketch2Render" element={<Sketch2RenderInteriorPage />} />
          <Route path="/FloorPlanner" element={<FloorPlannerPage/>}/>
          <Route exact path={`/CommunityGallery`} element={<CommunityGallery/>}/>
          <Route path="/PersonalGallery" element={<PersonalGallery/>}/>
          <Route path='/ForgotPasswordEmail' element={<ForgotPasswordEmailInput/>}/>
          <Route path="/ForgotPasswordChange" element={<ForgotPasswordChange/>}/>
          <Route path={`/PlansPage/:param`} element={<PlansPage />} />
          {/* <Route path="/PaymentForms" element={<PaymentForms />} /> */}
          {/* <Route path='/ChangeInfo' element={<ChangeInfo/>}/> */}
          <Route path="/ThanksPageRegister" element={<ThanksPageRegister/>}/>
          <Route path='/Privacy' element={<Privacy/>}/>
          <Route path='/Terms' element={<Terms/>}/>
          <Route path="/Dashboard/:paramLink" element={<Dashboard/>}/>
          <Route path="/Thankspage" element={<Thankspage />} />
          <Route path="/ThankspageMoedas" element={<ThankspageMoedas />} />
          <Route path="/Return" element={<Return />} />
          <Route path="/MoedasExtra" element={<MoedasExtra />} />
          <Route path="/InstallYourStudio" element={<InstallYourStudio />} />
          <Route path="/MakeYourAi" element={<MakeYourAi />} />
          <Route path='/WaitingConfirmation' element={<WaitingConfirmation/>}/>
          {/* Defina outras rotas para os componentes existentes */}
        </Routes>
      </ScrollToTop>
    </Router>
    </Context.Provider>
  );
}

export default App;

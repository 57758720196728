import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import InfoBox from "../InfoBox";

export default function ShapeStrength({ updateUserChoices }) {
  const [shapeStrength, setShapeStrength] = useState(2);
  const {t} = useTranslation();

  const handleShapeStrengthChange = (event) => {
    const value = event.target.value;
    setShapeStrength(value);
    updateUserChoices("ShapeStrengthRange", value);
  };

  return (
    <div className="items-center flex flex-col">
      <div className="flex">
      <h1 className='font-title lg:justify-center 
      font-bold py-8 lg:py-2 lg:mb-2 flex items-center'>
        {t("SHAPE STRENGTH")}
      <div className="w-[0.01px] h-[0.01px] relative">
        <InfoBox
        customClass={"absolute left-1 top-1/2 -translate-y-1/2"}
        text={t("How much the AI will try to keep the shape over trying to be creative.")}/>
      </div>
        </h1>
      </div>
      <div className="flex justify-between max-w-screen items-center w-[90vw] mb-4 lg:w-[25.5rem]">
        <p className="text-white font-title px-3 py-1 bg-black rounded-full">
        {t("1")}
        </p>
        <p className="text-white font-title px-3 py-1 bg-black rounded-full">
        {t("2")}
        </p>
        <p className="text-white font-title px-3 py-1 bg-black rounded-full">
        {t("3")}
        </p>
        <p className="text-white font-title px-3 py-1 bg-black rounded-full">
        {t("4")}
        </p>
        <p className="text-white font-title px-3 py-1 bg-black rounded-full">
        {t("5")}
        </p>
      </div>
      <input
        type="range"
        min="0"
        max="4"
        value={shapeStrength}
        onChange={handleShapeStrengthChange}
        className="lg:w-[24.8rem] w-[70vw] appearance-none h-1 rounded-full bg-black opacity-85 outline-none transition-all duration-300"
      />
      <p className='font-paragraph text-xl mt-3 mb-1 lg:text-justify'>
        {t("More creative the AI is less precise it tends to be")}</p>
    </div>
  );

}